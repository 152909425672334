import React from 'react';
import DefaultLayout from '../layouts/default';

export default ({ location }) => {

  return (
    <>
        <DefaultLayout location={location}>

          <h1>404</h1>

        </DefaultLayout>
      
    </>
  );
};

